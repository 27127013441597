.post-card {
    padding: 30px;
    margin: 20px 0; 
    width: 400px;
    border-radius: 0.2rem;
    cursor: pointer;
    box-shadow: 0px 2px 10px #d7dfe2;
    background-color: white;
}
.post-card:hover {  /* blog post hover */
    transform: scale(1.02);
    box-shadow: 0px 2px 15px #d7dfe2;
  }

.post-title {
    line-height: 1.5rem;
    margin: 1rem 0 0.5rem;
    font-weight: bold;
}

.post-sub {
    display: flex; 
    flex-direction: row;
}

.post-author {
    font-size: 12px;
    font-weight: 300;
    color: gray; 
    margin-right: 30px;
}

.post-date {
    font-size: 12px;
    font-weight: 300;
    color: gray; 
}

.post-content {
    padding-top: 20px;
    font-size: 12px;
    flex-wrap: wrap;
    overflow: hidden;
}