.App {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: cornsilk;
}

.search-form {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
}
.search-form > input {
  padding: 0;
}
.posts {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 50%;
}

button {
  padding: 10px;
  margin: 40px 0;
}